import { Injectable, inject } from '@angular/core';
import { ApolloError } from '@apollo/client';
import {
  CreateLocationGQL,
  UpdateLocationGQL,
  DeleteLocationInput,
  DeleteLocationGQL,
  CreateLocationInput,
  GetProfileLocationsGQL,
  Location,
  UpdateLocationInput,
} from '@designage/gql';
import { ILocation, ILocationForm } from '@desquare/interfaces';
import { CurrentUserService, ToasterService } from '@desquare/services';
import { map, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private profileId = inject(CurrentUserService).getCurrentProfileId();
  private createLocationGQL = inject(CreateLocationGQL);
  private updateLocationGQL = inject(UpdateLocationGQL);
  private deleteLocationGQL = inject(DeleteLocationGQL);
  private toasterService = inject(ToasterService);

  private getProfileLocationsGql$ = inject(GetProfileLocationsGQL).watch(
    {
      profileId: this.profileId,
    },
    {
      fetchPolicy: 'network-only',
    },
  );

  /**
   * Fetches profile locations from the API using GraphQL.
   *
   * This method returns an observable that emits the profile locations,
   * loading state, and any errors encountered during the request.
   *
   * @returns An observable that emits an object containing:
   * - `locations`: An array of `Location` objects.
   * - `loading`: A boolean indicating whether the request is still loading.
   * - `errors`: Any errors encountered during the request.
   */
  getProfileLocationsFromApi$() {
    return this.getProfileLocationsGql$.valueChanges.pipe(
      map(({ data, loading, errors }) => {
        const locations: ILocation[] = data.profile?.locations as ILocation[];
        return { locations, loading, errors };
      }),
    );
  }

  /**
   * Refreshes the locations data from the API
   * @returns Promise that resolves when locations are fetched
   * @private
   */
  private refreshLocationsFromApi() {
    this.getProfileLocationsGql$.refetch();
  }

  /**
   * Creates a new location using the provided input data.
   *
   * @param {ILocationForm} input - The input data for creating the location.
   * @returns {Promise<void|Location>} - Promise that resolves when the location is created successfully.
   *
   * @throws Will throw an error if the location creation fails.
   */
  async createLocation(input: ILocationForm): Promise<null | Location> {
    const result = await lastValueFrom(
      this.createLocationGQL.mutate(
        {
          input: {
            ...input,
            name: input.name ?? '',
            coordinates: {
              x: input.coordinates?.x ?? 0,
              y: input.coordinates?.y ?? 0,
            },
          },
        },
        { fetchPolicy: 'no-cache' },
      ),
    )
      .then(({ data }) => {
        if (data?.createLocation.isSuccessful) {
          this.toasterService.success('LOCATION_CREATED');
          this.refreshLocationsFromApi();
          return data.createLocation.location as Location;
        } else {
          this.toasterService.error('LOCATION_CREATE_FAILED');
          this.refreshLocationsFromApi();
          return null;
        }
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('Location creation error', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
        return null;
      });
    return result;
  }

  /**
   * Updates an existing location in the system
   * @param {ILocationForm} locationForm The location form data to update
   * @returns Promise that resolves when locations are refreshed after successful update
   * @throws ApolloError if the GraphQL mutation fails
   */
  async updateLocation(locationForm: ILocationForm) {
    const input = {
      ...locationForm,
      coordinates: {
        x: locationForm.coordinates?.x,
        y: locationForm.coordinates?.y,
      },
    } as UpdateLocationInput;

    await lastValueFrom(
      this.updateLocationGQL.mutate({ input }, { fetchPolicy: 'no-cache' }),
    )
      .then(({ data }) => {
        if (data?.updateLocation.isSuccessful) {
          this.toasterService.success('LOCATION_UPDATED');
        } else {
          this.toasterService.error('LOCATION_UPDATE_FAILED');
        }
        this.refreshLocationsFromApi();
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('Location update error', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
      });
  }

  /**
   * Deletes a location from the system
   * @param {DeleteLocationInput} input The delete location input containing location ID
   * @returns Promise that resolves when locations are refreshed after successful deletion
   * @throws ApolloError if the GraphQL mutation fails
   */
  async deleteLocation(input: DeleteLocationInput) {
    console.log('Deleting location', input);

    await lastValueFrom(
      this.deleteLocationGQL.mutate(
        {
          input,
        },
        { fetchPolicy: 'no-cache' },
      ),
    )
      .then(({ data }) => {
        if (data?.deleteLocation?.isSuccessful) {
          this.toasterService.success('LOCATION_DELETED');
        } else {
          this.toasterService.error('LOCATION_DELETE_FAILED');
        }
        this.refreshLocationsFromApi();
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('Location delete error', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
      });
  }

  toCreateLocationInput(input: ILocationForm): CreateLocationInput {
    const createLocationInput: CreateLocationInput = {
      name: input.name ?? '',
      city: input.city,
      coordinates: input.coordinates,
      country: input.country,
      phoneNumber: input.phoneNumber,
      profileId: input.profileId,
      region: input.region,
      streetAddress1: input.streetAddress1,
      streetAddress2: input.streetAddress2,
      zip: input.zip,
    };

    return createLocationInput;
  }
}
